export class StudentGenericDTO {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [index: string]: number | string | boolean | any | FileCallback;

  public key = 0;

  public id = 0;
  public firstName = "";
  public lastName = "";
  public birthDate = "";
  public date = "";
  public userMasterId = 0;
  public email = "";
  public phone = "";
  public buyerMasterEmail = "";

  constructor(init?: Partial<StudentGenericDTO>) {
    if (init === undefined) return;

    Object.assign(this, init);
  }
}
